import React from "react";
import CountUp from "react-countup";

const Section2 = () => {
    return (
        <div className="section3" id="about">
            <div className="container s41">
                <h1>About</h1>
                <p style={{ padding: "10px" }}>
                <h3> MAIN OBJECT OF COMPANY </h3>
            Trading ideas is an education platform and we teach financial subjects which comprise of technical and fundamental analysis. We are just an education platform and help people to understand the basics of stock markets. Trading ideas has helped more than 1000 students in last 2 months.
                </p>

                <br/>

                <p style={{ padding: "10px" }}>
                    <h2 style={{ color: "#0189DF" }}>Contact</h2> 
                    <b>+91 7986345700</b> <br/>
                    <b>support@tradingideas.co.in</b>
                </p>
            </div>
        </div>
    )
}

export default Section2;
